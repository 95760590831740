import "./ViewerBox.css";
import React from 'react';
import Viewer from '../Viewer/Viewer';
import { ViewersContext } from '../../Contexts/ViewerContext';

export default function ViewerBox() {
  const {viewers} = React.useContext(ViewersContext);
  const [ViewerList, setViewerList] = React.useState<React.JSX.Element[] | null>(null);
  React.useEffect(() => {
    if(viewers !== null && viewers.size > 0){
      const viewerList: React.JSX.Element[] = [];
      viewers.forEach((viewer) => {
        viewerList.push(<Viewer key={viewer.userId} username={viewer.username} websocketId={viewer.websocketId} avatar={viewer.avatar} userId={viewer.userId} />)
      });
      setViewerList(viewerList);
    } else {
      setViewerList([<div data-testid='no-viewers' style={{color: 'white'}} >No viewers</div>]);
    }
  }, [viewers]);

  return (
    <div className="viewer-box" data-testid='viewer-box'>
      {ViewerList}
    </div>
  );
}