import { configReply, sessionReply, userReply } from "../../../../shared/types";

function generateRandomString() {
  let randomString = '';
  const randomNumber = Math.floor(Math.random() * 10);
  for (let i = 0; i < 20 + randomNumber; i++) {
    randomString += String.fromCharCode(33 + Math.floor(Math.random() * 94));
  }
  return randomString;
}

function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export async function redirectToDiscordLogin(){
  document.cookie = "session=; Max-Age=-99999999;";
  let host = window.location.href;
  host = host.substring(0, host.length - 1);
  const storageRandomString = generateRandomString();
  localStorage.setItem('oauth-state', storageRandomString);
  const configResponse = await fetch(window.location.href + 'config');
  const configJson: configReply = await configResponse.json();
  const clientId = configJson.clientId;
  window.location.assign("https://discord.com/api/oauth2/authorize?client_id=" + clientId + "&redirect_uri=" + encodeURIComponent(host) + "&response_type=code&scope=identify&state=" + encodeURIComponent(storageRandomString));
}

export async function loginCheck(): Promise<boolean> {
  const windowUrl = window.location.search;
  const code = new URLSearchParams(windowUrl).get('code');
  const state = new URLSearchParams(windowUrl).get('state');
  const session = getCookie('session');
  if (code === null) {
    console.log('[info] No code found');
    if (session !== undefined || process.env.NODE_ENV === 'development'){
      console.log("[info] Session id is: " + session);

      const sessionResponse = await fetch(window.location.href + 'session');
      const sessionJson: sessionReply = await sessionResponse.json();
      if( sessionResponse.status !== 200 || typeof sessionJson === "string"){
        // If the session is not valid, remove it
        console.log('[info] Session expired, redirecting to Discord login');
        document.cookie = "session=; Expires==Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Strict";
        await redirectToDiscordLogin();
        return false;
      }

      console.log("SessionResponse json: " + JSON.stringify(sessionJson));
      // Set the session cookie to expire in 7 days on every session call, independent of the session expiration in the backend,
      // to avoid the session being removed from the client side while still being valid in the backend
      document.cookie = "session=" + sessionJson.sessionToken + "; Expires="+ new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) + "; SameSite=Strict";

      const userResponse = await fetch(window.location.href + 'users');
      const userJson: userReply = await userResponse.json();
      if ( userResponse.status === 401 || userResponse.status === 404 || typeof userJson === "string"){
        await redirectToDiscordLogin();
        return false;
      }
      localStorage.setItem('user', JSON.stringify(userJson));
      return true;
    }
    else {
      console.log('[info] Session undefined');
      await redirectToDiscordLogin();
      return false;
    }
  }
  else if (code){
    console.log('[info] Code found!');
    setTimeout(() => {
      if (localStorage.getItem('oauth-state') !==  state) {
        console.log("[error] State mismatch");
        window.location.href = "https://en.wikipedia.org/wiki/Clickjacking";
        return false;
      }
    }, 1000);

    const sessionResponse = await fetch('session?code=' + code);
    if ( sessionResponse.status === 401){
      await redirectToDiscordLogin();
      return false;
    }
    const sessionJson: {sessionToken: string, sessionExpiration: number} = await sessionResponse.json();
    console.log("SessionResponse json: " + JSON.stringify(sessionJson));
    document.cookie = "session=" + sessionJson.sessionToken + "; Expires="+ new Date(sessionJson.sessionExpiration + 1000 * 60 * 60 * 24 * 7) + "; SameSite=Strict";
    window.location.href = "/";
    // Return false because otherwise before the reload kicks
    // in the browser may try to start things like getting added to the viewers list,
    // which would fail because the user is not yet in the database
    return false;
  }
  return false;
}