import React, { useEffect, useState }  from 'react';
import "./Message.css";
import { MessageText } from '../../../../../shared/types';

export default function Message(props: MessageProps) {

  const { loadingHandler, value, isHalloween } = props;
  const { avatar, color, edited, id, username } = value;
  const [content, setContent] = useState<React.JSX.Element| null>(null);

  function convertTimestamp(id: number) {
    const milliseconds = BigInt(id) >> 22n;
    const timestamp = new Date(Number(milliseconds) + 1420070400000);
    const dateObject = new Date(timestamp.valueOf());
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    return hours + ":" + minutes + ":" + seconds;
  }

  function spoilerClick(event: React.MouseEvent<HTMLDivElement>) {
    if(event.currentTarget.style.opacity === "0") {
      event.currentTarget.style.opacity = "1";
    } else {
      event.currentTarget.style.opacity = "0";
    }
  }

  function spoilerCheck(element: string): number {
    if(element.match(/\|\|[^|]+\|\|/gmi)) {
      return element.indexOf("||");
    }
    return -1;
  }

  function spoilerURLCheck(element: string): number {
    if(element.includes("SPOILER_")) {
      const index = element.lastIndexOf(' ', element.indexOf("SPOILER_"))
      if(index === -1) {
        return 0;
      }
      return index;
    }
    return -1;
  }

  function URLCheck(element: string): number {
    if(element.includes("https")) {
      return element.indexOf("https");
    }
    if(element.includes("http")) {
      return element.indexOf("http");
    }
    return -1;
  }

  function emojiCheck(element: string): number {
    if(/<a?:.+?:\d+>/gm.test(element.toString())) {
      const emoji = /<a?:.+?:\d+>/gm.exec(element.toString());
      if(emoji !== null) {
        return emoji.index;
      }
    }
    return -1;
  }

  function getIndex(element: string): { spoiler: number, spoilerURL: number, url: number, emoji: number } {
    const spoilerIndex = spoilerCheck(element);
    const spoilerURLIndex = spoilerURLCheck(element);
    const urlIndex = URLCheck(element);
    const emojiIndex = emojiCheck(element);
    return { spoiler: spoilerIndex, spoilerURL: spoilerURLIndex, url: urlIndex, emoji: emojiIndex };
  }

  function getFirstIndex(element: string): number {
    const indexObject = getIndex(element);
    if(indexObject.spoiler >= 0 || indexObject.url >= 0 || indexObject.emoji >= 0) {
      return Math.min(...Object.values(indexObject).filter(index => index >= 0));
    }
    return -1;
  }

  async function mediaHandling(element: string): Promise<React.JSX.Element> {
    const contentJSX: React.JSX.Element[] = [];
    //Loop through the message and handle each spoiler, URL and emoji
    for(let index = getFirstIndex(element);index >= 0; index = getFirstIndex(element)) {
      // Take care of preceding text
      const textBefore = element.substring(0, index);
      if(textBefore.length > 0) {
        contentJSX.push(<span key={crypto.randomUUID()}>{textBefore}</span>);
      }
      element = element.substring(index, element.length);

      const indexObject = getIndex(element);
      index = getFirstIndex(element);

      // Take care of the URL, spoiler or emoji
      if(indexObject.spoiler === index) {
        //Find the end of the spoiler
        let spoilerEnd = element.indexOf("||", index + 2);
        if(spoilerEnd === -1) {
          spoilerEnd = element.length;
        }
        //Get the spoiler content
        const spoiler = element.substring(index + 2, spoilerEnd);
        const spoilerElement = await mediaHandling(spoiler);
        let additionalSpoilerElement;
        if(URLCheck(spoiler) >= 0) {
          additionalSpoilerElement = <div key={crypto.randomUUID()} className="spoilerURL" onClick={spoilerClick}></div>;
        } else {
          additionalSpoilerElement = <div key={crypto.randomUUID()} className="spoilerInline" onClick={spoilerClick}></div>;
        }
        contentJSX.push(<div key={crypto.randomUUID()} className='spoilerParent'>{spoilerElement}{additionalSpoilerElement}</div>);
        element = element.substring(spoilerEnd + 2, element.length);
      } else if(indexObject.spoilerURL === index) {
        //Find the end of the spoilerURL
        let spoilerURLEnd = element.indexOf(" ", index + 1);
        if(spoilerURLEnd === -1) {
          spoilerURLEnd = element.length;
        }
        const spoilerURL = element.substring(index, spoilerURLEnd);
        const spoilerURLElement = await URLHandling(spoilerURL);
        const additionalSpoilerElement = <div key={crypto.randomUUID()} className="spoilerURL" onClick={spoilerClick}></div>;
        contentJSX.push(<div key={crypto.randomUUID()} className='spoilerParent'>{spoilerURLElement}{additionalSpoilerElement}</div>);
        element = element.substring(spoilerURLEnd, element.length);
      } else if(indexObject.url === index) {
        //Find the end of the URL
        let urlEnd = element.indexOf(" ", index);
        if(urlEnd === -1) {
          urlEnd = element.length;
        }
        const url = element.substring(index, urlEnd);
        const URLElement = await URLHandling(url);
        contentJSX.push(URLElement);
        element = element.substring(urlEnd, element.length);
      } else if(indexObject.emoji === index) {
        //Find the end of the emoji
        let emojiEnd = element.indexOf(">") + 1;
        if(emojiEnd === -1) {
          emojiEnd = element.length;
        }
        const emoji = element.substring(0, emojiEnd);
        const emojiElement = await emojiHandling(emoji);
        element = element.substring(emojiEnd, element.length);
        contentJSX.push(emojiElement);
      }
    }
    //Take care of the remaining text
    if(element.length > 0) {
      contentJSX.push(<span key={crypto.randomUUID()}>{element}</span>);
    }
    return <>{contentJSX}</>;
  }

  async function URLHandling(urlString: string, alt: string = ""): Promise<React.JSX.Element> {
    const domainWhitelist = [
      "imgur.com",
      "redd.it",
      "discordapp.net",
      window.location.host,
    ];
    const validURL = URL.canParse(urlString);
    if(!validURL) {
      return <span key={crypto.randomUUID()}>{urlString}</span>;
    }
    const url = new URL(urlString);
    let width;
    let JSXResult;
    if(url.hostname === "cdn.discordapp.com" && url.pathname.startsWith("/emojis/")) {
      width = "30px";
    } else if(url.hostname === "cdn.discordapp.com" && url.pathname.startsWith("/stickers/")) {
      width = "180px";
    } else if(url.hostname === "cdn.discordapp.com" && url.pathname.startsWith("/attachments/")) {
      if(url.pathname.endsWith(".mp4")) {
        JSXResult = <video src={urlString} width="99%" key={crypto.randomUUID()} controls onLoadedData={loadingHandler}/>;
      } else {
        width = "99%";
      }
    } else if(domainWhitelist.some(domain => url.hostname.includes(domain))) {
      const urlResponse = await fetch(url, {method: 'HEAD'});
      const contentType = urlResponse.headers.get("Content-Type");
      if(urlResponse.status === 200 && contentType !== null) {
        if(contentType.includes("image")) {
          width = "99%";
        } else if(contentType.includes("video")) {
          JSXResult = <video src={urlString} width="99%" key={crypto.randomUUID()} controls onLoadedData={loadingHandler}/>;
        } else if(contentType.includes("audio")) {
          JSXResult = <audio src={urlString} controls key={crypto.randomUUID()} onLoadedData={loadingHandler}/>;
        } else {
          console.log("Unknown content type");
          JSXResult = <span key={crypto.randomUUID()}>{urlString}</span>;
        }
      } else {
        console.log("Content URL not responding: " + urlString + " " + urlResponse.status + " " + contentType);
        JSXResult = <span key={crypto.randomUUID()}>{urlString}</span>;
      }
    } else {
      JSXResult = <span key={crypto.randomUUID()}>{urlString}</span>;
    }
    if(JSXResult === undefined) {
      JSXResult = <img src={urlString} width={width} key={crypto.randomUUID()} alt={alt} data-testid="image" onLoadedData={loadingHandler}/>;
    }
    return JSXResult;
  }

  async function emojiHandling(emoji: string): Promise<React.JSX.Element> {
    const emojiId = emoji.substring(emoji.indexOf(":", emoji.indexOf(":") + 1) + 1, emoji.indexOf(">"));
    const emojiName = emoji.substring(emoji.indexOf(":"), emoji.indexOf(":", emoji.indexOf(":") + 1) + 1);
    let url;
    if(emoji.startsWith("<a")) {
      url = " https://cdn.discordapp.com/emojis/" + emojiId + ".gif ";
    } else {
      url = " https://cdn.discordapp.com/emojis/" + emojiId + ".webp ";
    }
    const URLElement = await URLHandling(url, emojiName);
    return <div className='Emoji' key={crypto.randomUUID()}>{URLElement}</div>;
  }

  async function asyncCaller(value: MessageText) {
    const message = await mediaHandling(value.content);
    setContent(<>{message}</>);
    setTimeout(() => {
      loadingHandler();
    }, 500);
  }

  useEffect(() => {
    void asyncCaller(value);
  }, [value]);

  return (
    <div className="chat-message">
      <div className="chat-message-left">
        <div className="chat-message-avatar">
          <img id="avatar" src={avatar.toString()} height="100%" alt="user" />
          { isHalloween ?
            <img id="hat" src="../../../../Witchhat.png" height="100%" alt="hat" />
            : <></>
          }
        </div>
      </div>
      <div className="chat-message-right">
        <div className="chat-message-header">
          <div className="user-name">
            <span style={{color}}>{username}</span>
          </div>
          <div className="chat-message-time">
            <span>{convertTimestamp(Number(id))}</span>
          </div>
          {edited &&
            <div className="chat-message-edited">
              <span>{"(edited)"}</span>
            </div>
          }
        </div>
        <div className="chat-message-content" key={crypto.randomUUID()}>
          {content}
        </div>
      </div>
    </div>
  );
}

type MessageProps = {
  value: MessageText;
  loadingHandler: () => void;
  isHalloween: boolean;
}