import React from "react";
import { websocket } from "./Websocket";
import { redirectToDiscordLogin } from "./Session";
import { ViewersContext } from "../Contexts/ViewerContext";
import { User, userReply, MessageBase, MessageViewerList, MessageViewer, Viewer } from "../../../../shared/types";

export function WebsocketListener(): React.JSX.Element {
  const {setViewers} = React.useContext(ViewersContext);
  async function websocketListeners() {
    if(websocket.readyState !== websocket.OPEN) {
      await new Promise(resolve => { websocket.addEventListener("open", resolve); });
    }

    websocket.addEventListener("message", messageListener);

    function messageListener(event: MessageEvent) {
      if(typeof event.data !== "string") {
        return;
      }
      const message: Partial<MessageBase> = JSON.parse(event.data);
      switch (message.command) {
      case "UPDATE_VIEWER_LIST": {
        const viewerMessage: MessageViewerList = JSON.parse(event.data);
        setViewers(new Map<string, Viewer>(viewerMessage.viewers));
        break;
      }}
    }
  }

  async function initialUpdate() {
    let user = localStorage.getItem('user');
    if (user === null) {
      const userResponse = await fetch(window.location.href + 'users');
      const userResponseJson: userReply = await userResponse.json();
      if (userResponse.status === 401 || userResponse.status === 404 || typeof userResponseJson === "string") {
        await redirectToDiscordLogin();
      }
      user = JSON.stringify(userResponseJson);
      localStorage.setItem('user', user);
    }
    const userJson: User = JSON.parse(user);
    const message: MessageViewer = {command: "UPDATE_VIEWER_LIST", userId: userJson.id};

    // Wait until the WebSocket is open
    try {
      await new Promise<void>((resolve, reject) => {
        if (websocket.readyState === websocket.OPEN) {
          resolve();
        } else {
          websocket.onopen = () => {
            resolve();
          };
          websocket.onerror = (error) => {
            reject(new Error(error.toString()));
          };
        }
      });

      // The WebSocket is open, you can send messages now
      websocket.send(JSON.stringify(message));
    } catch (error) {
      // There was an error opening the WebSocket
      console.error('Error opening WebSocket', error);
    }
  }

  React.useEffect(() => {
    void websocketListeners();
    void initialUpdate();
  }, []);

  return (
    <></>
  );
}