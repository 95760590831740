import React, { useEffect }  from 'react';
import OvenPlayer, { OvenPlayerInstance } from 'ovenplayer';
import "./Content.css";
import ViewerBox from './ViewerBox/ViewerBox';
import { playlistReply } from '../../../../shared/types';

export default function Content() {

  useEffect(() => {
    void asyncCaller();
  }, []);

  function playerAutoStart(player: OvenPlayerInstance) {
    let reloadInterval: NodeJS.Timeout;
    player.on('stateChanged', function (data){
      if((data.prevstate === "stalled" || data.prevstate === "playing") && data.newstate === "error") {
        reloadInterval = setInterval(async () => {
          console.log("Restarting stream");
          const sources = player.getSources();
          if(sources.length === 0) {
            const playlistResponse = await fetch(window.location.href + 'playlist');
            const playlists: playlistReply = await playlistResponse.json();
            player.load(playlists);
          } else {
            player.load(sources);
          }
        }, 3000);
        player.on('stateChanged', function (data){
          if(data.newstate === "playing") {
            console.log("Stream restarted");
            clearInterval(reloadInterval);
          }
        });
      }
    });
  }

  // Get playlist from server via fetch and await
  async function asyncCaller() {
    const playlistResponse = await fetch(window.location.href + 'playlist');
    const playlists: playlistReply = await playlistResponse.json();
    const hlsScript = document.createElement('script');
    hlsScript.src = "https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js";
    document.body.appendChild(hlsScript);
    hlsScript.onload = () => {
      const player = OvenPlayer.create('player_id', {
        "autoStart": true,
        "autoFallback": true,
        "disableSeekUI": true,
        "showSeekControl": false,
        "volume": 100,
        "image": "/biribiri.png",
        // Set to 0 again when https://github.com/AirenSoft/OvenPlayer/issues/399 is fixed
        sources: playlists,
      });
      playerAutoStart(player);
    };
  }


  return (
    <>
      <div className="main-area">
        <div className="player-wrapper">
          <div id='player_id' />
        </div>
        <ViewerBox/>
      </div>
    </>
  );
}