import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import "./Header.css";
import { websocket } from '../../Misc/Websocket';
import { configReply, MessageBase } from '../../../../../shared/types';

export default function Header(props: HeaderProps) {

  const backupImage = new URL("/AquaPadoru.webp", window.location.href);
  const rainbowProgressRef = useRef<number>(0);
  const [guildLogo, setGuildLogo] = useState<URL>(backupImage);
  const [rainbowProgress, setRainbowProgress] = useState(rainbowProgressRef.current);
  const isInitialLogoRef = useRef<boolean>(true);

  //Config
  async function fetchConfig() {
    const configResponse = await fetch(window.location.href + 'config')
    const configResponseJson: configReply = await configResponse.json();
    if(configResponseJson.guildURL.length > 0) {
      setGuildLogo(new URL(configResponseJson.guildURL));
      let link: HTMLLinkElement | null = document.querySelector("#favicon");
      if (link === null) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = configResponseJson.guildURL;
    }
  }

  async function messageListenerInitializer(){
    if(websocket.readyState !== websocket.OPEN) {
      await new Promise(resolve => { websocket.addEventListener("open", resolve); });
    }
    websocket.addEventListener("message", messageListener);
  }

  function messageListener(event: MessageEvent) {
    if(typeof event.data !== "string") {
      return;
    }
    const message: Partial<MessageBase> = JSON.parse(event.data);
    if(message.command === "DUPLICATE_MESSAGE") {
      if(rainbowProgressRef.current < 100) {
        rainbowProgressRef.current += 40;
        setRainbowProgress(rainbowProgressRef.current);
        if(rainbowProgressRef.current >= 100) {
          props.activateJipee();
        }
      }
    }
  }

  useEffect(() => {
    isInitialLogoRef.current = false;
  }, [guildLogo]);

  useEffect(() => {
    void fetchConfig();
    void messageListenerInitializer();
  }, []);

  //Rainbow Progress
  useEffect(() => {
    setInterval(() => {
      if(rainbowProgressRef.current > 0) {
        rainbowProgressRef.current -= 0.5;
        setRainbowProgress(rainbowProgressRef.current);
      }
    }, 500);
  }, []);

  //Elements
  return (
    <div className='chat-header'>
      <div className="chat-header-background">
        <div className="rainbow-bar" style={{width: rainbowProgress + "%"}}/>
      </div>
      <div className="chat-header-foreground">
        <div className='chat-header-flex-horizontal'>
          <div className="chat-header-text-left">
            Axis Order
          </div>
          <div className="guild-logo">
            {<img src={guildLogo.toString()} alt="guild logo" />}
          </div>
          <div className="chat-header-text-right">
            <Popup
              trigger={<span id='info-span'> Railgun ⓘ</span>}
              modal
            >
              <div className='popup-content-title'>
                <span>Information about Railgun 🛤️🔫</span>
                <input type="hidden" role="none" />
              </div>
              <div className='popup-content-source'>
                <span>Source:</span>
                <div className='popup-content-source-text'>
                  <a href="https://gitlab.zerwin.me/Zerwin/railgun" target="_blank">Repository</a>
                </div>
              </div>
              <div className='popup-content-license'>
                <span>License:</span>
                <div className='popup-content-license-text'>
                  <span>AGPLv3, see here: </span>
                  <a href="https://gitlab.zerwin.me/Zerwin/railgun/-/blob/main/LICENSE" target="_blank">License</a>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
}

type HeaderProps = {
  activateJipee: () => void;
};