import "./Chat.css";
import React, { useEffect, useState, useRef }  from 'react';
import Snowfall from 'react-snowfall';
import ChatBox from './ChatBox/Box';
import Message from './Message/Message';
import Header from './Header/Header';
import { websocket } from '../Misc/Websocket';
import { MessageBase, MessagePrevious, MessageText } from '../../../../shared/types';

export default function Chat() {
  const containerRef = useRef<HTMLDivElement|null>(null);
  const chatRef = useRef<HTMLDivElement|null>(null);
  const [messageHistory, setMessageHistory] = useState<MessageText[]>([]);
  const [isSpring, setIsSpring] = useState<boolean>(false);
  const [isDecember, setIsDecember] = useState<boolean>(false);
  const [isHalloween, setIsHalloween] = useState<boolean>(false);
  const [isJipee, setIsJipee] = useState<boolean>(false);
  const [snowfallStyle, setSnowfallStyle] = useState({});

  let fallingFlakes;
  if(isSpring) {
    const imagesFalling = document.createElement('img');
    imagesFalling.src = '../../../FlowerPetal.png';
    fallingFlakes = [imagesFalling];
  } else if(isHalloween) {
    const imagesFallingCandy = document.createElement('img');
    const imagesFallingPumpkin = document.createElement('img');
    imagesFallingCandy.src = '../../../CandyCorn.png';
    imagesFallingPumpkin.src = '../../../Pumpkin.png';
    fallingFlakes = [imagesFallingCandy, imagesFallingPumpkin];
  } else if(isDecember) {
    const imagesFalling = document.createElement('img');
    imagesFalling.src = '../../../AquaPadoru.webp';
    fallingFlakes = [imagesFalling];
  } else if(isJipee) {
    const imagesFalling = document.createElement('img');
    imagesFalling.src = '../../../AquaYeah.webp';
    fallingFlakes = [imagesFalling];
  }

  useEffect(() => {
    if(new Date().getMonth() === 3) {
      setIsSpring(true);
    } else if(new Date().getMonth() === 9) {
      setIsHalloween(true);
    } else if(new Date().getMonth() === 11) {
      setIsDecember(true);
    }
  }, []);

  function activateJipee() {
    setSnowfallStyle({});
    setIsJipee(true);
    setTimeout(() => {
      setSnowfallStyle({
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 0s 2s, opacity 2s linear",
      });
      setTimeout(() => {
        setIsJipee(false);
      }, 2000);
    }, 5000);
  }

  //Message Handling
  function fillMessageHistory(message: MessagePrevious) {
    const tempMessageHistory = [...messageHistory];
    if(message.previousMessages !== undefined) {
      for (const previousMessage of message.previousMessages) {
        tempMessageHistory.push(previousMessage);
      }
    }
    setMessageHistory(tempMessageHistory);
  }

  function findMessageIndex(messageHistory: MessageText[], message: MessageText, range = 15): number {
    const tempMessageHistory = messageHistory.map((element) => element.id);
    return tempMessageHistory.indexOf(message.id, -range);
  }

  function editMessage(message: MessageText) {
    setMessageHistory(messageHistory => {
      const targetIndex = findMessageIndex(messageHistory, message);
      if(targetIndex !== -1) {
        messageHistory = [...messageHistory];
        messageHistory[targetIndex] = message;
      }
      return messageHistory;
    });
  }

  function deleteMessage(message: MessageText) {
    setMessageHistory(messageHistory => {
      const targetIndex = findMessageIndex(messageHistory, message);
      if(targetIndex !== -1) {
        messageHistory = [...messageHistory];
        messageHistory.splice(targetIndex, 1);
      }
      return messageHistory;
    });
  }

  //Websocket
  function messageListener(event: MessageEvent) {
    if(typeof event.data !== "string") {
      return;
    }
    const message: Partial<MessageBase> = JSON.parse(event.data);
    switch (message.command) {
    case "SEND_MESSAGE_TO_WEB": {
      const textMessage: MessageText = JSON.parse(event.data);
      const blockedUsers = localStorage.getItem("blockedUsers")
      if(blockedUsers !== null) {
        const blockedUsersArray = blockedUsers.split(",");
        if(blockedUsersArray.includes(textMessage.userId)) {
          return;
        }
      }
      setMessageHistory(messageHistory => [...messageHistory, textMessage]);
      break;
    }
    case "EDIT_MESSAGE": {
      const newMessage: MessageText = JSON.parse(event.data);
      editMessage(newMessage);
      break;
    }
    case "DELETE_MESSAGE": {
      const deletedMessage: MessageText = JSON.parse(event.data);
      deleteMessage(deletedMessage);
      break;
    }
    case "LOAD_PREVIOUS_MESSAGES": {
      const previousMessages: MessagePrevious = JSON.parse(event.data);
      fillMessageHistory(previousMessages);
      break;
    }
    }
  }

  async function messageListenerInitializer(){
    if(websocket.readyState !== websocket.OPEN) {
      await new Promise(resolve => { websocket.addEventListener("open", resolve); });
    }
    websocket.addEventListener("message", messageListener);
    const messageLoadPrevObj: MessagePrevious = {command: "LOAD_PREVIOUS_MESSAGES", loadPreviousMessages: 15};
    const messageLoadPrev = JSON.stringify(messageLoadPrevObj);
    websocket.send(messageLoadPrev);
  }

  function systemMessage(message: string) {
    const errorMessage: MessageText = { command: "SEND_MESSAGE_TO_WEB", id: "9999999999999999999", username: "💥System💥", userId: "0", avatar: new URL(window.location.href + "/logo512.png"), content: message };
    setMessageHistory(messageHistory => [...messageHistory, errorMessage]);
  }

  useEffect(() => {
    window.addEventListener("reloadPage", () => {
      systemMessage("💥Connection to the server has been lost, Page will reload in 10 sec💥");
    });
    void messageListenerInitializer();
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, []);

  //Scrolling
  function scrollToBottom() {
    if(chatRef.current !== null) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  //Elements
  return (
    <div className='flex-chat' data-testid='chat'>
      <div className="chat-body-snowflake">
        { isSpring ?
          <Snowfall images={fallingFlakes} radius={[8, 12]} speed={[0.1, 1]} wind={[-0.1, 0.5]} snowflakeCount={100}/>
          : <></>
        }
        { isHalloween ?
          <Snowfall images={fallingFlakes} radius={[8, 12]} speed={[0.1, 1]} wind={[-0.1, 0.5]} snowflakeCount={100}/>
          : <></>
        }
        { isDecember ?
          <Snowfall images={fallingFlakes} radius={[4, 6]} speed={[0.1, 1.5]} wind={[-0.1, 0.1]} />
          : <></>
        }
        { isJipee ?
          <Snowfall style={snowfallStyle} images={fallingFlakes} changeFrequency={50} snowflakeCount={15} radius={[30, 40]} speed={[4, 4]} wind={[0, 0]} />
          : <></>
        }
      </div>
      <Header activateJipee={activateJipee} />
      <div className="chat-body" ref={chatRef}>
        {messageHistory.map(message => <Message key={message.id} value={message} loadingHandler={scrollToBottom} isHalloween={isHalloween} />)}
        <div className="hiddenScroll" ref={containerRef}></div>
      </div>
      <ChatBox systemMessage={systemMessage}/>
    </div>
  );
}